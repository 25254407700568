<template>
<p>这是用来测试的，康康！</p>
</template>

<script>
export default {
  name: "test"
}
</script>

<style scoped>

</style>
